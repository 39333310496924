import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Chakra-powered theme
import { UIKitProvider } from "ui-kit/provider";

// Initialize React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, react-query retries 3 times on failed query. Turning this feature off
      retry: false,
      // By default, react-query will refetch data on window focus. Turning this feature off
      refetchOnWindowFocus: false
    }
  }
});

export const BaseProviders = ({ children }) => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <UIKitProvider>
          <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
          </QueryClientProvider>
        </UIKitProvider>
      </QueryParamProvider>
    </Router>
  );
};
