import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { useOrdersSummary } from "hooks/orders";
import { isRetailerALockedAccount } from "shared/retailer-helpers";
import { getUserRetailer } from "store/selectors/user";
import userAPI from "api/user-api";

export const useDashboardStats = () => {
  const { data: ordersSummary } = useOrdersSummary();
  const retailer = useSelector(getUserRetailer);

  const isLockedAccount = useMemo(() => {
    if (isEmpty(retailer)) {
      return false;
    }

    return isRetailerALockedAccount({ retailer, ordersSummary });
  }, [retailer, ordersSummary]);

  return {
    isLockedAccount
  };
};

export const useRetailerData = () => {
  return useQuery(["retailerData"], async () => {
    const { value_listed, recent_listing_date } = await userAPI.fetchUserData();
    return {
      inventoryListedValue: value_listed,
      recentListingDate: recent_listing_date
    };
  });
};
