import axios from "axios";
import { errorToast } from "shared/toast-service";

export class ApiHandler {
  async request({
    url,
    headers,
    body,
    method,
    params,
    skipToken = false,
    skipToast = true,
    retailerId: otherRetailerId
  }) {
    const token = skipToken ? null : localStorage.getItem("accessToken");
    const retailerId = sessionStorage.getItem("retailerId") || otherRetailerId;

    const adminHeaders = retailerId
      ? {
          "retailer-id": retailerId
        }
      : {};

    const tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};

    const defaultHeaders = {
      ...adminHeaders,
      ...tokenHeader,
      "Content-Type": "application/json"
    };

    const requestHeaders = headers
      ? {
          ...headers,
          ...defaultHeaders
        }
      : defaultHeaders;

    try {
      const r = await axios({
        url,
        headers: requestHeaders,
        data: JSON.stringify(body),
        params: params || {},
        method
      });

      if (r.status < 400) {
        return Promise.resolve(r.data);
      }

      // A whitelist-based middleware can be instated here for handling
      //   non-generic 4xx errors later on, based on route & response.
      // This is TBD, as making toasts based on speciffic scenarios might be
      //   cleaner if done as thunk side effects.

      if (!skipToast) {
        if (r.status === 403) {
          errorToast({
            title: "An error occurred",
            description: "Your requested resource is not available.",
            duration: 0
          });
        }

        if (r.status === 500) {
          errorToast({
            title: "Server Error",
            description: "We're sorry, a server error has occurred.",
            duration: 0
          });
        }
      }

      return Promise.reject(r);
    } catch (error) {
      if (!skipToast) {
        errorToast({
          title: "An error occurred",
          description: "Something went wrong when loading this page."
        });
      }

      return Promise.reject({ status: error?.response?.status, data: error?.response?.data });
    }
  }

  get(options) {
    return this.request({ ...options, method: "GET" });
  }

  post(params) {
    return this.request({ ...params, method: "POST" });
  }

  put(options) {
    return this.request({ ...options, method: "PUT" });
  }

  patch(options) {
    return this.request({ ...options, method: "PATCH" });
  }

  delete(options) {
    return this.request({ ...options, method: "DELETE" });
  }

  options(options) {
    return this.request({ ...options, method: "OPTIONS" });
  }
}

const API = new ApiHandler();

export default API;
