import { Image, Box } from "@chakra-ui/react";
import React, { useState } from "react";

import { pendingImage, placeholder } from "static/images";
import DaysListedIndicator from "../days-listed-indicator/days-listed-indicator";

/**
 * @type {React.FC<{
 *  className?: string,
 * src: string,
 * errorSrc: any,
 * fallbackSrc: any,
 * dateCreated?: string,
 * displayDaysListed?: boolean }>}
 */
const LazyBackgroundImage = ({
  src,
  errorSrc = pendingImage,
  fallbackSrc = placeholder,
  dateCreated,
  displayDaysListed = false,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(() => src);

  return (
    <Box height="100%">
      <DaysListedIndicator dateCreated={dateCreated} displayDaysListed={displayDaysListed} />
      <Image src={imgSrc} fallbackSrc={fallbackSrc} onError={() => setImgSrc(errorSrc)} loading="lazy" {...rest} />
    </Box>
  );
};

export default LazyBackgroundImage;
