const REFETCH_TIME = 1000 * 60 * 5; // 5 minutes

export const DASHBOARD_QUERY_CONFIG = {
  retry: false,
  keepPreviousData: true,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchInterval: REFETCH_TIME,
  refetchIntervalInBackground: true
};

export const DASHBOARD_POSTS_NUMBER = 3;
