import { createContext, useContext } from "react";
import { useQuery } from "react-query";

import { StringParam, useQueryParam, withDefault } from "use-query-params";

import ordersApi from "api/orders-api";
import { ORDERS_PER_PAGE, ORDERS_PER_LIST } from "constants/orders";
import { allOrdersMapper } from "mappers/orders";
import { DASHBOARD_QUERY_CONFIG } from "./constants";

export const OrdersContext = createContext({});
export const OrdersProvider = ({ children, ...rest }) => (
  <OrdersContext.Provider value={rest}>{children}</OrdersContext.Provider>
);

export const useOrdersContext = () => {
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error("useOrdersContext() must be used within an <OrdersProvider>");
  }
  return context;
};

export const useSort = () => useQueryParam("sort", withDefault(StringParam, "-modified"));

export const useOrders = ({ type, state: orderState, page, ordering = "-modified", retailerId }) => {
  return useQuery(["orders", { type, state: orderState, ordering, page }], async () => {
    const payload = await ordersApi.getOrders(orderState, type, ORDERS_PER_PAGE * (page - 1), ordering);
    return allOrdersMapper(payload, retailerId);
  });
};

export const useMySales = ({ page, ordering = "-modified", retailerId }) => {
  return useQuery(["salesOrders", { type: "sale", ordering, page }], async () => {
    const payload = await ordersApi.getOrders("all", "sale", ORDERS_PER_LIST * (page - 1), ordering, ORDERS_PER_LIST);
    return allOrdersMapper(payload, retailerId);
  });
};

export const useMyPurchases = ({ page, ordering = "-modified", retailerId }) => {
  return useQuery(["purchasesOrders", { type: "purchase", ordering, page }], async () => {
    const payload = await ordersApi.getOrders(
      "all",
      "purchase",
      ORDERS_PER_LIST * (page - 1),
      ordering,
      ORDERS_PER_LIST
    );
    return allOrdersMapper(payload, retailerId);
  });
};

export const useOrdersSummary = () => {
  return useQuery(["ordersSummary"], async () => ordersApi.getOrdersSummary(), DASHBOARD_QUERY_CONFIG);
};

export const useOrdersGlobalFeed = () => {
  return useQuery(
    ["ordersGlobalFeed"],
    async () => {
      const { data } = await ordersApi.getOrdersGlobalFeed();
      return data;
    },
    DASHBOARD_QUERY_CONFIG
  );
};
