import { createSelector } from "reselect";

import { STATUS_TYPES } from "constants/status-types";
import { userNames } from "shared/user-helpers";

export const getUser = state => state.user || {};

/** @return {MRAPI.User} */
export function getUserData(state) {
  return getUser(state).data || {};
}

export function getUserDataStatus(state) {
  return getUser(state).status;
}

export function getIsUserLoaded(state) {
  return getUserDataStatus(state) === STATUS_TYPES.LOADED;
}

export const getUserRetailer = createSelector(getUserData, user => user.retailer ?? {});
export const getUserRetailerContact = createSelector(getUserData, getUserRetailer, (user, retailer) => {
  if (user.retailercontact) {
    return user.retailercontact;
  }

  if (user.is_staff || user.is_super) {
    return retailer.contacts?.[0] ?? {};
  }

  return {};
});

export const getRetailerSalesChannels = createSelector(getUserRetailer, retailer => {
  return retailer.sales_channels ?? [];
});

export const getRetailerHasSalesChannels = createSelector(getRetailerSalesChannels, channels => channels.length > 0);

/**
 * @return { first_name?: string, last_name?: string }
 */
export const getUserNames = createSelector(getUserData, getUserRetailerContact, (user, contact) => {
  return userNames(user, contact);
});

export const getUserRetailerIsShopify = createSelector(getUserData, user => user.shopify ?? false);
export const getUserRetailerIsLightspeed = createSelector(getUserData, user => {
  return user.integrations.find(integration => integration.name === "Lightspeed") ?? false;
});

/* eslint-disable-next-line */
export type RetailerType = "SHOPIFY" | "LIGHTSPEED" | "MANUAL";

export const getRetailerType = createSelector(getUserData, user => {
  const containsLightspeed = user?.integrations?.find(integration => integration.name === "Lightspeed") ?? false;
  const containsShopify = user.shopify ?? false;
  if (containsShopify) {
    return "SHOPIFY";
  }
  if (containsLightspeed) {
    return "LIGHTSPEED";
  }
  return "MANUAL";
});

export const getUserRetailerIsShellAccount = createSelector(getUserData, user => {
  return Boolean(user?.is_shell_account) as boolean;
});
export function getUserRetailerId(state) {
  return getUserRetailer(state)?.id;
}

export function getUserRetailerName(state) {
  return getUserRetailer(state)?.business_name;
}

export function getUserRetailerSalesChannel(state) {
  return getUserRetailer(state)?.buyer_for_sales_channel;
}

export function getUserIsStaff(state) {
  return !!getUserData(state)?.is_staff;
}

export const isAdminUser = createSelector(getUserData, user => !!(user?.is_super || user?.is_staff));

export const getUserPayoutAmount = createSelector(getUserData, user => user.amount_owed);
