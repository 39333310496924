import { Box } from "@chakra-ui/react";
import { DateTime } from "luxon";

import React from "react";

const DaysListedIndicator = ({ dateCreated, displayDaysListed = false }) => {
  const ago = DateTime.fromISO(dateCreated).diffNow("days").days;
  const daysAgo = Math.abs(Math.round(ago));

  if (!displayDaysListed) {
    return null;
  }

  const days = daysAgo === 1 ? "day" : "days";

  const getColor = () => {
    switch (true) {
      case daysAgo > 90:
        return { backgroundColor: "#FFEDED", color: "#C32727" };
      case daysAgo > 60 && daysAgo <= 90:
        return { backgroundColor: "#F9F2CE", color: "#796700" };
      case daysAgo <= 60:
        return { backgroundColor: "#F8F8F8", color: "#848D90" };
      default:
        return { backgroundColor: "#F8F8F8", color: "#848D90" };
    }
  };

  return (
    <Box
      className="listing-images__display__container__item__days_listed"
      position="absolute"
      top="1px"
      left="1px"
      fontSize="14px"
      zIndex={3}
      px="10px"
      borderRadius="4px"
      ml={2}
      mt={2}
      style={getColor()}
      lineHeight="24px"
    >
      {daysAgo === 0 ? "listed today" : `${daysAgo} ${days} listed`}
    </Box>
  );
};

export default DaysListedIndicator;
