import PropTypes from "prop-types";

// style
import SideNavLayout from "components/layout/side-nav-layout";
import "./layout.scss";

const Layout = ({ children }) => {
  return <SideNavLayout>{children}</SideNavLayout>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
