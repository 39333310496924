export const DECLINE_FEE = 10;
export const CANCEL_FEE = 20;

export const ORDERS_PER_PAGE = 10;
export const ORDERS_PER_LIST = 50;

export const ORDER_GREEN_LINES = ["discount"];
export const ORDER_BOLD_LINES = ["subtotal", "total", "grand-total", "total_provider_value"];

export const ORDERS_TABS = [
  { id: "sale", label: "Sales", link: "/orders/approved/sales" },
  { id: "purchase", label: "Purchases", link: "/orders/purchases" },
  { id: "swap", label: "Swaps", link: "/orders/swaps" }
];

export const CARRIER_FEDEX = "fedex";
export const CARRIER_UPS = "ups";
export const CARRIER_USPS = "usps";
export const CARRIER_USPS_STAMPS = "stamps_com";

export const ORDER_CARRIER_OPTIONS = [
  { label: "Fedex", value: CARRIER_FEDEX },
  { label: "UPS", value: CARRIER_UPS },
  { label: "USPS", value: CARRIER_USPS_STAMPS }
];

export const ORDER_CARRIER_OPTIONS_USPS_ONLY = ORDER_CARRIER_OPTIONS.filter(o => o.value === CARRIER_USPS_STAMPS);

export const CARRIER_TRACKING_URL_PREFIX = {
  [CARRIER_FEDEX]: `https://www.fedex.com/fedextrack/?trknbr=`,
  [CARRIER_UPS]: `https://www.ups.com/track?`,
  [CARRIER_USPS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=`
};

export const ORDERS_SORT_OPTIONS = [
  { value: "-modified", label: "Newest to Oldest" },
  { value: "modified", label: "Oldest to Newest" }
];

export const ORDER_DECLINE_REASON_OPTIONS = {
  provider: [
    { value: 14, label: "Already sold the unit" },
    { value: 12, label: "Prefer to swap/sell the size run" },
    { value: 13, label: "This is a pair w/another listing" },
    { value: 15, label: "The unit is damaged" },
    { value: 16, label: "Returned it to the brand" },
    { value: 17, label: "I'm out of town" },
    { value: 1919, label: "Not ready to part with it yet" }
  ],
  receiver: [
    { value: 0, label: "No longer need it" },
    { value: 1, label: "Purchased it from the brand" }
  ]
};

export const ORDER_CANCELLATION_REASON_OPTIONS = [
  { value: 1, label: "I don't have the right product" },
  { value: 2, label: "I don't have an item in the right size" },
  { value: 3, label: "I don't have an item in the right color" },
  { value: 4, label: "I don't have time to package it" },
  { value: 5, label: "A requested item is damaged" }
];

export const ORDER_ACTION_TYPES = {
  REQUEST_STARTED: "REQUEST_STARTED",
  CHECKED_OUT: "CHECKED_OUT",
  REQUEST_SWAP: "REQUEST_SWAP",
  REQUEST_SWAP_RESPONSE: "REQUEST_SWAP_RESPONSE",

  APPROVE_OR_DECLINE: "APPROVE_OR_DECLINE",
  APPROVE_OR_DECLINE_REQUEST: "APPROVE_OR_DECLINE_REQUEST",
  APPROVE_OR_DECLINE_MODIFIED: "APPROVE_OR_DECLINE_MODIFIED",
  APPROVE_OR_DECLINE_SWAP: "APPROVE_OR_DECLINE_SWAP",

  MODIFIED: "MODIFIED",
  MODIFIED_TRANSACTION: "MODIFIED_TRANSACTION",

  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",

  PAID: "PAID",
  APPROVED: "APPROVED",
  SALE_APPROVED: "SALE_APPROVED",
  APPROVED_REQUEST: "APPROVED_REQUEST",
  APPROVED_MODIFIED: "APPROVED_MODIFIED",
  PAYMENT_METHOD_NEEDED: "PAYMENT_METHOD_NEEDED",

  PICKUP_SCHEDULED: "PICKUP_SCHEDULED",

  SHIPPING: "SHIPPING",
  IN_ROUTE: "IN_ROUTE",
  DELIVERED: "DELIVERED",
  RECEIVED: "RECEIVED",
  RATE_NOW: "RATE_NOW",
  RATED: "RATED",

  RMA_REQUESTED: "RMA_REQUESTED",
  RMA_APPROVED: "RMA_APPROVED",
  RMA_SHIP: "RMA_SHIP"
};

/**
 * @typedef {"PENDING"
 * | "DECLINED"
 * | "CANCELLED"
 * | "APPROVED"
 * | "SHIPPED"
 * | "SHIPPING"
 * | "SHIPPING"
 * | "DELIVERED" } TOrderStatus
 * @type {Record<TOrderStatus, TOrderStatus>}
 */
export const ORDER_STATUS = {
  PENDING: "PENDING",
  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
  APPROVED: "APPROVED",
  SHIPPED: "SHIPPED",
  SHIPPING: "SHIPPING",
  DELIVERED: "DELIVERED",
  SHIPMENT_EXCEPTION: "SHIPMENT EXCEPTION"
};

export const IS_MR_PERCENT_FEE_AVAILABLE = false;

/*
The buy and sell verbs reflect MY intention as a retailer,
  if the other retailer is fulfilling my request, I want to *buy*
  if the other retailer listed and I initiated a transaction, I want to *buy*
*/
export const ORDER_COPY_VERBS = {
  pending: {
    // Reflects order status
    buy: {
      // Reflects order type
      me: "want to buy", // Reflects order initiator
      other: "wants to sell" // Reflects order initiator
    },
    // Comments above descibe the templating system
    sell: {
      me: "want to sell",
      other: "wants to buy"
    },
    swap: {
      me: "want to swap",
      other: "wants to swap"
    }
  },
  declined: {
    buy: {
      me: "won't be receiving",
      other: "won't be receiving"
    },
    sell: {
      me: "won't be selling",
      other: "won't be selling"
    },
    swap: {
      me: "won't be swapping",
      other: "won't be swapping"
    }
  },
  cancelled: {
    buy: {
      me: "won't be receiving",
      other: "won't be receiving"
    },
    sell: {
      me: "won't be selling",
      other: "won't be selling"
    },
    swap: {
      me: "won't be swapping",
      other: "won't be swapping"
    }
  },
  approved: {
    buy: {
      me: "bought",
      other: "sold"
    },
    sell: {
      me: "sold",
      other: "bought"
    },
    swap: {
      me: "swapped",
      other: "swapped"
    }
  },
  shipped: {
    buy: {
      me: "bought",
      other: "sold"
    },
    sell: {
      me: "sold",
      other: "bought"
    },
    swap: {
      me: "swapped",
      other: "swapped"
    }
  },
  shipping: {
    buy: {
      me: "bought",
      other: "sold"
    },
    sell: {
      me: "sold",
      other: "bought"
    },
    swap: {
      me: "swapped",
      other: "swapped"
    }
  },
  delivered: {
    buy: {
      me: "bought",
      other: "sold"
    },
    sell: {
      me: "sold",
      other: "bought"
    },
    swap: {
      me: "swapped",
      other: "swapped"
    }
  },
  "shipment exception": {
    buy: {
      me: "want to buy",
      other: "wants to sell"
    },
    sell: {
      me: "want to sell",
      other: "wants to buy"
    },
    swap: {
      me: "want to swap",
      other: "wants to swap"
    }
  }
};
