import APIHandler from "api/api-handler";

import { API_ROUTES } from "api/const";
import { ORDERS_PER_PAGE } from "constants/orders";

const ordersApi = {
  getOrders: (state, type, offset, ordering = "-modified", limit = ORDERS_PER_PAGE) => {
    /**
     * Purchases
     *  Pending: pre_shipping
     *  Approved: approved
     *  Shipped: shipping
     *  Delivered: delivered
     *  Declined: declined
     *  Cancelled: cancelled
     */

    /**
     * Sale
     *  Pending: pre_shipping
     *  Needs to Ship: approved
     *  Shipped: shipping
     *  Delivered: delivered
     *  Declined: declined
     *  Cancelled: cancelled
     */

    let stages = {};
    if (type === "sale") {
      stages = {
        pending: "pre_shipping", // Pending
        approved: "approved", // Needs to Ship
        shipped: "shipping", // Shipping
        delivered: "delivered", // Delivered
        declined: "declined", // Declined
        cancelled: "cancelled" // Cancelled
      };
    }

    if (type === "purchase") {
      stages = {
        pending: "pre_shipping", // Pending
        approved: "approved", // Approved
        shipped: "shipping", // In-Transit
        delivered: "delivered", // Delivered
        declined: "declined", // Declined
        cancelled: "cancelled" // Cancelled
      };
    }

    const params = {
      limit,
      type,
      offset,
      ordering,
      stage: stages[state]
    };
    return APIHandler.get({ url: API_ROUTES.ORDERS, params });
  },
  getOrder: id => APIHandler.get({ url: API_ROUTES.generate_ORDER(id) }),
  getOrderHistory: id => APIHandler.get({ url: API_ROUTES.generate_ORDER_HISTORY(id) }),
  getOrdersSummary: () => APIHandler.get({ url: API_ROUTES.ORDERS_SUMMARY }),
  getOrdersGlobalFeed: () => APIHandler.get({ url: API_ROUTES.ORDERS_FEED }),
  getPackingSlip: id => APIHandler.get({ url: API_ROUTES.generate_PACKINGSLIP(id) }).then(response => response),

  approveOrder: id => APIHandler.patch({ url: API_ROUTES.generate_ORDER_APPROVE(id) }),
  cancelOrder: (id, formData) => APIHandler.patch({ url: API_ROUTES.generate_ORDER_CANCEL(id), body: formData }),
  declineOrder: ({ id, ownRole, formData }) =>
    APIHandler.patch({ url: API_ROUTES.generate_ORDER_DECLINE(id, ownRole), body: formData }),
  approveModifiedOrder: id => APIHandler.patch({ url: API_ROUTES.generate_MODIFIED_ORDER_APPROVE(id) })
};

export default ordersApi;
