import {
  Alert,
  AlertDescription,
  AspectRatio,
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  ListItem,
  OrderedList
} from "@chakra-ui/react";
import { faAngleLeft, faAngleRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
import Slider from "react-slick";

import { WINDOW_WIDTH_TABLET } from "constants/layout/layout";
import { getImagePreview, revokeImagePreview } from "shared/helpers";
import { validateImageDimensions } from "shared/image-helpers";
import photo from "static/images/photo.svg";

import { fileKey } from "../helpers";

import "./dropzone.scss";

const baseStyle = {
  position: "relative",
  width: "100%",
  padding: "50% 20px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderColor: "#5FBAC9",
  backgroundColor: "#5FBAC9",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  borderStyle: "solid"
};

const focusedStyle = {
  borderColor: "#D2F5F8"
};

const acceptStyle = {
  borderColor: "#D2F5F8"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

const FILE_ERROR_MESSAGES = {
  [ErrorCode.FileInvalidType]: "The image is invalid. We only accept: PNG, JPEG or WEBP images.",
  [ErrorCode.FileTooLarge]: "Maximum upload file size: 25 MB.",
  [ErrorCode.TooManyFiles]: `You exceed the max file length`,
  imageQuality: "Please add a higher quality image.",
  imageAlreadyAdded: "Image has already been added"
};

const renderArrow = icon => (
  <button>
    <FontAwesomeIcon icon={icon} />
  </button>
);

const sliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 5,
  dots: false,
  infinite: false,
  vertical: false,
  prevArrow: renderArrow(faAngleLeft),
  nextArrow: renderArrow(faAngleRight),
  responsive: [
    {
      breakpoint: WINDOW_WIDTH_TABLET,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }
  ]
};

export const Dropzone = ({
  error: errorProp,
  photoUpload = { isLoading: false },
  maxFilesLength,
  existingFileKeys = [],
  onImage,
  onRemoveImage
}) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    initialize();
  }, []);
  // carousel related
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isImageActive = imageIndex => imageIndex === currentImageIndex;

  const { isLoading } = photoUpload;
  const getPreview = useCallback(file => getImagePreview(file), [files]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    // Basic file extension checking for image types accepted by our backend.
    // Upgrade note: in react-dropzone v13 this prop is an object instead of a string.
    accept: "image/png,image/jpeg,image/webp",
    maxFiles: maxFilesLength,
    maxSize: 25 * 10 ** 6,
    onDrop: (acceptedFiles, fileRejections) => {
      setError(null);

      if (fileRejections.length > 0) {
        const errorCode = fileRejections[0]?.errors?.[0]?.code;
        setError(FILE_ERROR_MESSAGES[errorCode]);
        return;
      }

      if (existingFileKeys.length + files.length + acceptedFiles.length > maxFilesLength - 1) {
        setError(FILE_ERROR_MESSAGES[ErrorCode.TooManyFiles]);
        return;
      }

      acceptedFiles.forEach(file => {
        const key = fileKey(file);
        if (files.find(f => fileKey(f) === key) || existingFileKeys.find(exKey => exKey === key)) {
          setError(`${FILE_ERROR_MESSAGES.imageAlreadyAdded}: ${file.name}`);
          return;
        }

        const image = new Image();

        image.onload = () => {
          if (validateImageDimensions({ width: image.width, height: image.height })) {
            setFiles(prev =>
              produce(prev, draft => {
                draft.push(file);
              })
            );
            onImage(file);
          } else {
            setError(FILE_ERROR_MESSAGES.imageQuality);
          }
        };

        image.src = getPreview(file);
        revokeImagePreview(file);
      });
    }
  });

  useEffect(() => {
    // An error may be passed in from form validation.
    setError(errorProp);
  }, [errorProp]);

  const initialize = () => {
    setFiles([]);
    setError(null);
  };

  const handleClickRemove = file => {
    const idx = files.findIndex(f => fileKey(f) === fileKey(file));

    setFiles(prev =>
      produce(prev, draft => {
        draft.splice(idx, 1);
      })
    );

    onRemoveImage(file);

    if (currentImageIndex === idx) {
      setCurrentImageIndex(Math.max(idx - 1, 0));
    }
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const hasAtLeastOneFile = files.length > 0;

  return (
    <div className="dropzone">
      {hasAtLeastOneFile ? (
        <div className="dropzone__display">
          {files.map((file, index) => (
            <AspectRatio key={fileKey(file)} ratio={3 / 4}>
              <Flex justify="center" sx={{ "& > img": { objectFit: "contain" } }}>
                <Box
                  as="img"
                  className={cx("dropzone__display__item", {
                    "dropzone__display__item--crt": isImageActive(index)
                  })}
                  src={getPreview(file)}
                  alt={file.name}
                  {...getRootProps()}
                />
              </Flex>
            </AspectRatio>
          ))}
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <img src={photo} alt="Camera" />
          <Button colorScheme="gray" bg="white" color="gray.700" className="dropzone__upload_button">
            UPLOAD IMAGES
          </Button>
        </div>
      )}
      <div className="dropzone__thumbs_container">
        <Slider
          {...sliderSettings}
          afterChange={currentSlide => setCurrentImageIndex(currentSlide)}
          style={{ display: "flex" }}
        >
          {files.map((file, index) => (
            <div className="dropzone__thumb" key={fileKey(file)} role="group">
              {!isLoading && (
                <IconButton
                  icon={<FontAwesomeIcon icon={faTrashAlt} color="#00252F" size="xs" />}
                  aria-label="Remove image"
                  onClick={() => handleClickRemove(file)}
                  position="absolute"
                  top="0"
                  right="0"
                  zIndex="docked"
                  boxSize="5"
                  minW="unset"
                  bg="white"
                  border="0.5px solid #DEDEDE"
                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                  // Chakra parent pseudoselector: button is only visible when "group" is hovered.
                  opacity="0"
                  _groupHover={{ opacity: "1", transition: "opacity 0.2s" }}
                />
              )}
              <div
                className={cx({
                  dropzone__thumb_inner: !isLoading,
                  "dropzone__thumb_inner--disabled": isLoading,
                  "dropzone__thumb_inner--active": isImageActive(index)
                })}
                onClick={() => setCurrentImageIndex(index)}
                onKeyDown={() => setCurrentImageIndex(index)}
              >
                <img className="dropzone__thumb_inner__thumb_img" src={getPreview(file)} alt={file.name} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {hasAtLeastOneFile && (
        <Box textAlign="center" {...getRootProps()}>
          <Button colorScheme="gray" mt="4">
            ADD MORE IMAGES
          </Button>
        </Box>
      )}
      {error && (
        <Alert status="error" mt="2">
          <AlertDescription px="2" my="3">
            {error}
          </AlertDescription>
          <CloseButton position="absolute" right="1" top="1" onClick={() => setError(null)} />
        </Alert>
      )}
      <Box textAlign="center" pt="6" pb="9">
        Best Practice: Upload multiple high quality images, brand stock photos sell best!
      </Box>
      <Alert status="warning">
        <OrderedList>
          <ListItem>We only accept: PNG, JPEG, or WEBP images.</ListItem>
          <ListItem>Maximum upload file size: 25 MB.</ListItem>
          <ListItem>Please do not add more than {maxFilesLength} images.</ListItem>
          <ListItem>Please add a larger or higher quality image.</ListItem>
        </OrderedList>
      </Alert>
    </div>
  );
};
