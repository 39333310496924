import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  SimpleGrid
} from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "react-use";

import { Form, Heading, SmartInput } from "ui-kit";

import { take } from "lodash";
import { setRetailer, unsetRetailer } from "store/actions/auth";
import { clearCart } from "store/actions/cart";
import { getRetailerId } from "store/selectors/auth";
import { Icon, Refresh } from "ui-kit/icon";

export const RetailerImpersonateModal = ({ disclosure, modalProps = {} }) => {
  const { isOpen, onClose } = disclosure;
  const dispatch = useDispatch();
  const defaultRetailerId = useSelector(getRetailerId);

  const [recentRetailers, setRecentRetailers] = useLocalStorage("recent-retailers", [], {
    serializer: val => JSON.stringify(val),
    deserializer: val => JSON.parse(val)
  });

  const inputRef = useRef();

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const clearRetailer = useCallback(() => {
    dispatch(unsetRetailer());
    dispatch(clearCart());
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      recentRetailers.unshift(data.retailerId);
      setRecentRetailers(take(Array.from(new Set(recentRetailers)), 6));
      dispatch(setRetailer(data.retailerId));
      dispatch(clearCart());
    },
    [dispatch, recentRetailers]
  );

  const handleClickRetailer = value => {
    setValue("retailerId", value);
    handleSubmit(onSubmit)();
  };

  const resetRecents = () => {
    setRecentRetailers([]);
  };

  return (
    <Portal>
      <Modal __css={{ mt: 10 }} initialFocusRef={inputRef} isCentered isOpen={isOpen} onClose={onClose} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Who do you want to impersonate?</ModalHeader>
            <ModalBody pb="4">
              <SmartInput
                name="retailerId"
                ref={inputRef}
                label="Retailer ID"
                rules={{
                  required: "Retailer ID is required"
                }}
              />
              {!!recentRetailers.length && (
                <>
                  <Heading my="4" d="inline-flex" alignItems="center" textStyle="body">
                    Recent Retailers{" "}
                    <IconButton
                      size="sm"
                      icon={<Icon as={Refresh} boxSize="5" />}
                      ml="2"
                      transform="scale(0.7)"
                      title="Reset Recent Retailers"
                      onClick={resetRecents}
                      variant="secondary"
                    />
                    {/* <Button size="xs" ml="3" minW="auto" px="2" onClick={resetRecents}>
                      Reset
                    </Button> */}
                  </Heading>
                  <SimpleGrid columns={2} gap="3">
                    {recentRetailers.map(retailerId => {
                      return (
                        <Button
                          w="full"
                          size="sm"
                          onClick={() => handleClickRetailer(retailerId)}
                          key={retailerId}
                          variant="ghost"
                        >
                          Use Retailer {retailerId}
                        </Button>
                      );
                    })}
                  </SimpleGrid>
                </>
              )}
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter borderTopWidth="1px" justifyContent="space-between" sx={{ gap: "2" }}>
              {defaultRetailerId && (
                <Button type="button" size="sm" variant="tertiary" onClick={clearRetailer}>
                  Clear
                </Button>
              )}
              <Button ml="auto" type="submit" size="sm">
                Impersonate
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Portal>
  );
};
