import { notificationRoseBg } from "static/images";

/*
Scheduling settings

ALERT_START_DATE and ALERT_END_DATE can be set to a Date object, e.g.
    const ALERT_START_DATE = new Date("Nov 10, 2023 00:00:00 EST");
or null, meaning no time restriction should be applied.

any combination of Date() objects and null is supported (e.g. start date with no end date, etc).
ENABLE_SITEWIDE_ALERT must be true for alert to ever be displayed, regardless of scheduling settings.
*/

const ENABLE_SITEWIDE_ALERT = true;
const ALERT_START_DATE = null;
const ALERT_END_DATE = new Date("Feb 14, 2024 23:59 EST");

const SITEWIDE_ALERT_MESSAGE = `<span style="font-size:1.1em">Reminder: Transaction fee changes go into effect February 15th. <a style="font-size:1.1em;padding-left:5px" href="https://announcements.maxretail.com/fee"> Learn more here.</a></span>`;

const currentDate = new Date();
const isDuringScheduledTimeRange =
  (!ALERT_START_DATE || currentDate >= ALERT_START_DATE) && (!ALERT_END_DATE || currentDate <= ALERT_END_DATE);

/* eslint-disable react/no-danger */
const SitewideAlertBanner = () => {
  const alertBanner = (
    <div
      style={{
        padding: "1em",
        backgroundImage: `url(${notificationRoseBg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >
      <h1 style={{ color: "#fff" }} dangerouslySetInnerHTML={{ __html: SITEWIDE_ALERT_MESSAGE }} />
    </div>
  );
  return ENABLE_SITEWIDE_ALERT && isDuringScheduledTimeRange ? alertBanner : <></>;
};

export default SitewideAlertBanner;
