import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Link, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { getUserIsStaff } from "store/selectors/user";

import { SideNavLinkList } from "components/layout/side-nav";
import { SideNavLink } from "components/layout/side-nav/side-nav-link";
import { RetailerImpersonateModal } from "components/shared/retailer-impersonate-modal";

import "./header-impersonation-box.scss";

const AdminImpersonationBox = ({ retailerId, disclosure }) => {
  if (retailerId) {
    return (
      <Alert mb="2" variant="solid" status="info">
        <AlertIcon />
        <AlertDescription>
          <AlertTitle mb="2">Impersonating Retailer {retailerId}</AlertTitle>
          <Button variant="tertiary" size="sm" onClick={disclosure.onOpen}>
            Change
          </Button>
        </AlertDescription>
      </Alert>
    );
  }
  return (
    <Alert mb="2" variant="top-accent" status="warning" color="gray.800">
      <AlertIcon />
      <AlertDescription ml="auto">
        <Button variant="tertiary" size="sm" onClick={disclosure.onOpen}>
          Choose Retailer
        </Button>
      </AlertDescription>
    </Alert>
  );
};

const HeaderImpersonationBox = ({ retailerId, showLinks = true, ...rest }) => {
  const isAdmin = useSelector(getUserIsStaff);

  const disclosure = useDisclosure();

  if (!isAdmin) return null;

  return (
    <Box className="header-impersonation-box" {...rest}>
      <RetailerImpersonateModal disclosure={disclosure} />
      <span className="header-impersonation-box__pill">Admin</span>
      <AdminImpersonationBox disclosure={disclosure} retailerId={retailerId} />

      {showLinks && (
        <SideNavLinkList>
          <SideNavLink as={Link} isExternal href={import.meta.env.VITE_ADMIN_URL}>
            Django
          </SideNavLink>
          <SideNavLink as={Link} isExternal href={import.meta.env.VITE_LEGACY_ADMIN_URL}>
            Legacy
          </SideNavLink>
          <SideNavLink to="/admin/verifications">Admin</SideNavLink>
        </SideNavLinkList>
      )}
    </Box>
  );
};

export default HeaderImpersonationBox;
