import { ORDER_ACTION_TYPES } from "../constants/orders";

export const sortByDate = (oa1, oa2) => {
  return Date.parse(oa1.modified) - Date.parse(oa2.modified);
};

const HIDDEN_ACTION = null;

const BACKFILL_TYPES_CONST = {
  119: ORDER_ACTION_TYPES.DELIVERED,
  121: ORDER_ACTION_TYPES.DECLINED,
  910: ORDER_ACTION_TYPES.PICKUP_SCHEDULED,
  920: ORDER_ACTION_TYPES.PICKUP_SCHEDULED,
  120: ORDER_ACTION_TYPES.CANCELLED,

  211: ORDER_ACTION_TYPES.REQUEST_SWAP_RESPONSE,
  221: ORDER_ACTION_TYPES.APPROVE_OR_DECLINE,
  223: ORDER_ACTION_TYPES.REQUEST_STARTED,
  225: ORDER_ACTION_TYPES.APPROVE_OR_DECLINE_MODIFIED,

  231: ORDER_ACTION_TYPES.RMA_REQUESTED,
  232: ORDER_ACTION_TYPES.RMA_APPROVED,
  930: ORDER_ACTION_TYPES.RMA_SHIP,
  810: ORDER_ACTION_TYPES.PAYMENT_METHOD_NEEDED
};

export const processStaleActions = (actions, order_actions, order) => {
  // 1. go through order.actions and map them
  // 2. go through order.history and log the last ID
  // 3. go through order.history and backfill types based on 1. and modify statuses if there's continuity down the line

  let actionsCopy = [...actions];
  const lastOfType = {};

  actionsCopy = actionsCopy.map(action => {
    const actionCopy = { ...action };

    lastOfType[action.type || BACKFILL_TYPES_CONST[action.state]] = action.id;
    actionCopy.type = action.type || BACKFILL_TYPES_CONST[action.state];

    return actionCopy;
  });

  const HAS_COMPLETION =
    !!lastOfType[ORDER_ACTION_TYPES.PICKUP_SCHEDULED] ||
    !!lastOfType[ORDER_ACTION_TYPES.DECLINED] ||
    !!lastOfType[ORDER_ACTION_TYPES.PICKUP_SCHEDULED] ||
    !!lastOfType[ORDER_ACTION_TYPES.CANCELLED];

  actionsCopy.sort(sortByDate);

  actionsCopy = actionsCopy.filter(action => action.type !== ORDER_ACTION_TYPES.PAID);

  return actionsCopy.map((action, index) => {
    const actionCopy = { ...action };

    actionCopy.type = BACKFILL_TYPES_CONST[+actionCopy.state] || actionCopy.type;

    let lastAction = {};

    if (index > 0) {
      const lastIndex = index - 1;

      lastAction = {
        ...actionsCopy[lastIndex],
        type: BACKFILL_TYPES_CONST[actionsCopy[lastIndex].state] || actionsCopy[lastIndex].type
      };
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.APPROVE_OR_DECLINE) {
      if (action.complete) {
        const nextAction = actionsCopy[index + 1];

        if (nextAction) {
          if (
            (nextAction.type || BACKFILL_TYPES_CONST[nextAction.state]) === ORDER_ACTION_TYPES.DECLINED &&
            nextAction.participant === actionCopy.participant
          ) {
            actionCopy.type = HIDDEN_ACTION;
          }
          actionCopy.type = ORDER_ACTION_TYPES.APPROVED;
        } else {
          actionCopy.type = ORDER_ACTION_TYPES.APPROVED;
        }
      } else if (HAS_COMPLETION) {
        actionCopy.type = HIDDEN_ACTION;
      }
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.APPROVE_OR_DECLINE_MODIFIED) {
      if (action.complete) {
        actionCopy.type = ORDER_ACTION_TYPES.APPROVED_MODIFIED;
      } else if (HAS_COMPLETION) {
        actionCopy.type = HIDDEN_ACTION;
      }
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.CHECKED_OUT) {
      if (actionCopy.dynamic_description !== "Checked Out") {
        if (actionCopy.dynamic_description === "Swap Requested") {
          actionCopy.type = ORDER_ACTION_TYPES.REQUEST_SWAP;
        } else {
          actionCopy.type = ORDER_ACTION_TYPES.APPROVE_OR_DECLINE_REQUEST;
        }
      } else if (
        order.initiator !== action.participant &&
        lastAction.type === ORDER_ACTION_TYPES.REQUEST_STARTED &&
        action.complete
      ) {
        actionCopy.type = ORDER_ACTION_TYPES.APPROVED_REQUEST;
      } else {
        const nextAction = actionsCopy[index + 1];
        if (
          nextAction &&
          nextAction.type === ORDER_ACTION_TYPES.DECLINED &&
          nextAction.participant === actionCopy.participant
        ) {
          // Checked out simulated before decline
          actionCopy.type = HIDDEN_ACTION;
        }
      }
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.APPROVED) {
      const nextAction = actionsCopy[index + 1];
      if (
        nextAction &&
        nextAction.type === ORDER_ACTION_TYPES.DECLINED &&
        nextAction.participant === actionCopy.participant
      ) {
        // Checked out simulated before decline
        actionCopy.type = HIDDEN_ACTION;
      }
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.PAYMENT_METHOD_NEEDED) {
      if (actionCopy.complete || !lastAction.complete || (lastAction.complete && actionsCopy[index + 1])) {
        actionCopy.type = HIDDEN_ACTION;
      }
    }

    if (actionCopy.type === ORDER_ACTION_TYPES.PAID) {
      actionCopy.type = HIDDEN_ACTION;
    }

    return actionCopy;
  });
};

export const orderActionsMapper = (action, retailerId, order) => {
  const actionProps = {
    ...action,
    isOwn: action.participant === retailerId,
    title: `order ${order.id}`
  };

  switch (action.type) {
    case ORDER_ACTION_TYPES.CHECKED_OUT:
      return {
        ...actionProps,
        title: "Hi there! This is what I want to buy.",
        footer: "Do you still have it available?",
        contributions: order.provider_contributions
      };

    case ORDER_ACTION_TYPES.REQUEST_STARTED:
      return {
        ...actionProps,
        title: "Hi there! I can fulfill one of your requests.",
        footer: "Do you still need it?",
        contributions: order.provider_contributions
      };

    case ORDER_ACTION_TYPES.APPROVED_REQUEST:
      return {
        ...actionProps,
        title: "Yes, I still want to buy it.",
        footer: "Do you still have it available?",
        contributions: order.provider_contributions
      };

    case ORDER_ACTION_TYPES.DECLINED:
      return {
        ...actionProps,
        title: "Sorry, I have to decline the order."
      };

    case ORDER_ACTION_TYPES.MODIFIED:
    case ORDER_ACTION_TYPES.MODIFIED_TRANSACTION:
      return {
        ...actionProps,
        contributions: order.provider_contributions,
        title: `Retailer-00${action.participant} has proposed a change for this transaction.`
      };

    case ORDER_ACTION_TYPES.RECEIVED:
    case ORDER_ACTION_TYPES.DELIVERED:
      return {
        ...actionProps,
        title: "Your order has been delivered."
      };

    case ORDER_ACTION_TYPES.RATED:
      return {
        ...actionProps,
        type: HIDDEN_ACTION
      };

    case ORDER_ACTION_TYPES.SHIPPING:
      return {
        ...actionProps,
        title: "Your package is in transit."
      };

    case ORDER_ACTION_TYPES.REQUEST_SWAP:
    case ORDER_ACTION_TYPES.REQUEST_SWAP_RESPONSE:
      return {
        ...actionProps,
        title: "Hi there! This is what I want from you.",
        footer: "If you want to move these, pick something you want from my inventory.",
        contributions: order.provider_contributions
      };

    case ORDER_ACTION_TYPES.PICKUP_SCHEDULED:
      return { ...actionProps, title: "The pickup has been scheduled." };

    case ORDER_ACTION_TYPES.CANCELLED:
      return { ...actionProps, title: "The order has been cancelled." };

    case ORDER_ACTION_TYPES.APPROVED:
    case ORDER_ACTION_TYPES.SALE_APPROVED:
      return {
        ...actionProps,
        title: "Yes, happy to sell it."
      };

    case ORDER_ACTION_TYPES.APPROVED_MODIFIED:
      return {
        ...actionProps,
        title: "Yes, the changes work out for me."
      };

    case ORDER_ACTION_TYPES.RMA_REQUESTED:
      return {
        ...actionProps,
        title: "An RMA request has been submitted.",
        contributions: order.provider_contributions
      };

    case ORDER_ACTION_TYPES.RMA_APPROVED:
      return { ...actionProps, title: "The RMA request was approved by an admin." };

    case ORDER_ACTION_TYPES.RMA_SHIP:
      return { ...actionProps, title: "The RMA has been scheduled for pickup" };

    default:
      return {
        ...actionProps
      };
  }
};

export const allOrdersMapper = (orders, myRetailerId) => {
  const ordersCopy = { ...orders };

  const { results } = ordersCopy;

  const modifiedResults = results.map(order => {
    const sortedActions = order?.actions?.sort(sortByDate);

    // TODO: Needs to be revisited once swaps go live

    const roles =
      myRetailerId === order.receiver
        ? {
            own: "receiver",
            other: "provider"
          }
        : { own: "provider", other: "receiver" };

    const initiator = (sortedActions || [])[0]?.participant;

    return { ...order, roles, initiator, actions: sortedActions };
  });

  return { ...orders, results: modifiedResults };
};
